@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@media only screen and (min-width: 860px) {
  html {
    scroll-behavior: smooth !important;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #1b313b;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #054245;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #20b182;
}

body {
  scroll-padding-top: 100px !important;
  margin: 0;
  padding: 0;
}

div {
  scroll-margin-top: 100px !important;
}

@font-face {
  font-family: 'Cocogoose';
  src: url('../public/fonts/Cocogoose-Pro-Regular.woff2');
  font-family: 'Montserrat';
  src: url('../public/fonts/Montserrat-VariableFont_wght.ttf');
}
